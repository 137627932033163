input {
  max-width: 450px;
  width: auto;
  border: none;
  margin: 0 0 25px 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 10px;
}

button {
  width: 125px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: none;
  padding: 7px;
  text-align: center;
  cursor: pointer;
}

/*button[type="submit"] {
  background-color: #4c566a;
  color: white;
  font-size: 14px;
  font-weight: 900;
  border-radius: 5px;
  text-transform: uppercase;
  padding: 15px;
}*/

.googleBtn {
  width: 225px;
  margin: 0 0 25px 0;
  display: flex;
  justify-content: center;
  font-size: 16px;
}

.googleBtn img {
  width: 16px;
  height: 16px;
  padding: 0;
  margin: 0 5px;
  vertical-align: middle;
}

.nav {
  display: flex;
  text-decoration: none;
  list-style-type: none;
  justify-content: flex-start;
  margin: 0 0 50px;
  padding: 0;
}

.nav li {
  margin: 0 10px;
}
