@import "styles/colors.scss";

@font-face {
  font-family: "planet_kosmosregular";
  src: url("fonts/kosmos-webfont.woff2") format("woff2"),
    url("fonts/kosmos-webfont.woff") format("woff"),
    url("fonts/kosmos-webfont.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: top;
  color: $scc-body;
  font-family: "Montserrat", sans-serif;
  background-color: #ffffff;
  min-height: 90vh;
  padding-top: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h2,
h3 {
  color: $scc-pink;
}

.btn-secondary {
  background-color: $scc-pink;
  border-color: $scc-pink;
  &:hover {
    background-color: $scc-peach;
    border-color: $scc-peach;
  }
}

.App-link {
  color: #61dafb;
}

.stray-cat {
  margin-top: 3vh;
  font-size: 3em;
  font-family: "planet_kosmosregular", sans-serif;
  padding: 15px;
  a {
    color: inherit;
    text-decoration: none;
  }
}

#portfolioRow {
  margin-top: 2vmin;
  font-size: calc(10px + 1vmin);
  text-align: center;
}

.portfolioLogo {
  width: 100%;
}

.portfolioLogoList {
  height: 35%;
}

.row {
  margin-top: 15px;
}

textarea {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.gradient-animation {
  text-align: center;

  background: linear-gradient(
    to right,
    $scc-peach 20%,
    $scc-pink 40%,
    $scc-pink 60%,
    $scc-peach 80%
  );
  background-size: 200% auto;

  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: shine 1.5s linear infinite;
  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
}
